<template>
  <ul
    v-infinite-scroll="load"
    infinite-scroll-disabled="disabled"
    class="infinite-list"
    style="overflow:auto"
  >
    <li v-for="(item, ind) in noticeData" :key="ind" class="infinite-list-item">
      <div class="infinite-list-title clearfix" @click="showMoreContent(item)">
        <span
          class="title-label el-icon-arrow-down"
          :class="activeId.includes(item.Id) ? 'show-more' : ''"
          :title="item.Title"
        >{{ item.Title || '--' }}</span>
        <span class="fr type">{{ item.Type ? '平台公告' : '更新公告' }}</span>
      </div>
      <!--  添加图片  -->
      <div class="infinite-list-img" :class="{ 'is-planet': item.Type }">
        <div v-if="!item.Type" class="infinite-list-logo">
          <img v-if="logo" alt="logo" :src="logo" class="infinite-list-image">
          <span class="infinite-list-name">明源云天际·服务云</span>
        </div>
        <div class="infinite-list-content">
          <div v-if="!item.Type" class="infinite-list-tips">
            明源云天际·服务云版本迭代更新
          </div>
          <i v-else class="infinite-list-tips">平台通知</i>
          <div class="infinite-list-btn">
            {{ !item.Type ? '更新公告' : '通知内容' }}
          </div>
        </div>
      </div>
      <div
        class="notice-content_html"
        :class="activeId.includes(item.Id) ? '' : 'show-more'"
        v-html="item.Content"
      />
      <div class="infinite-list-time el-icon-time">
        发布时间：{{ item.PublishTime || '--' }}
      </div>
    </li>
    <p v-if="loading && noticeData.length > 0">加载中...</p>
  </ul>
</template>

<script>
import logo from '@/assets/logo/logo.svg'

export default {
  name: 'NoticeItems',
  components: {},
  props: {
    noticeData: {
      // 当前数据
      type: Array,
      default() {
        return []
      }
    },
    loading: {
      // 数据加载中
      type: Boolean,
      default: false
    },
    noMore: {
      // 没有更多数据
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      logo,
      activeId: this.noticeData[0] ? [this.noticeData[0].Id] : []
    }
  },
  computed: {
    disabled() {
      const { noMore, loading } = this
      return loading || noMore
    }
  },
  watch: {
    noticeData: {
      deep: true,
      handler(noticeData) {
        this.activeId = noticeData[0] ? [noticeData[0].Id] : []
      }
    }
  },
  created() {},
  mounted() {},
  methods: {
    // 点开更多
    showMoreContent({ Id }) {
      const hasActiveContent = this.activeId.findIndex((val) => val === Id)
      if (hasActiveContent === -1) {
        this.activeId = [...this.activeId, Id]
      } else {
        this.activeId.splice(hasActiveContent, 1)
      }
    },
    load() {
      if (!this.noMore) {
        this.$emit('loadMoreData')
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/styles/variables.scss';
.infinite-list {
  height: calc(80vh - 150px);
  padding-right: 20px;
}
.infinite-list-item {
  background: #ffffff;
  border-radius: 5px;
  border: 1px solid #dde0e3;
  margin-bottom: 20px;
  padding: 0 15px 15px 15px;
}
.infinite-list-img {
  display: inline-block;
  width: 100%;
  height: 160px;
  padding: 20px 20px 40px 20px;
  background: rgb(238, 245, 251) url('~@/assets/tool/notice_bg.png') no-repeat
    center center / cover;
  border-radius: 4px;
  margin-bottom: 6px;
  position: relative;
  .infinite-list-logo {
    position: absolute;
    top: 20px - 5px;
    left: 20px;
    .infinite-list-image {
      width: 14px;
      vertical-align: middle;
      margin-right: 5px;
    }
    .infinite-list-name {
      vertical-align: middle;
      font-size: 12px;
    }
  }
  .infinite-list-content {
    padding-top: 40px;
    .infinite-list-tips {
      font-size: 20px;
      color: rgba(0, 0, 0, 0.85);
      font-weight: 600;
    }
    .infinite-list-btn {
      padding: 0 0 0 10px;
      font-size: 14px;
      height: 24px;
      line-height: 24px;
      color: #fff;
      width: 220px;
      background: url('~@/assets/panelBg/blue_bg.png') no-repeat center center /
        cover;
      border-radius: 25px;
    }
  }

  &.is-planet {
    background: rgba(252, 57, 28, 1) url('~@/assets/tool/planet_bg.png')
      no-repeat center center / cover;
    .infinite-list-content {
      padding-top: 10px;
    }
    .infinite-list-tips {
      display: inline-block;
      font-size: 38px;
      color: #fff;
      margin-bottom: 20px;
    }
    .infinite-list-btn {
      color: rgb(214, 0, 0);
      background: url('~@/assets/panelBg/orange_bg.png') no-repeat center center /
        cover;
    }
  }
}
.infinite-list-title {
  height: 52px;
  line-height: 52px;
  padding-left: 40px;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  position: relative;
  margin-bottom: 15px;
  cursor: pointer;
  .type {
    color: #999999;
  }
  &:hover {
    .title-label {
      color: #0f6dff;
    }
  }
}
.title-label {
  font-size: 14px;
  font-weight: 500;
  color: #333333;
  height: 100%;
  line-height: 53px;
  display: inline-block;
  text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: calc(100% - 80px);
  &::before {
    position: absolute;
    left: 10px;
    transform: rotate(-90deg);
  }
  &.show-more {
    &::before {
      transform: rotate(0deg);
    }
  }
}
.infinite-list-time {
  margin-top: 15px;
  font-size: 12px;
  font-weight: 400;
  width: 100%;
  color: rgba(0, 0, 0, 0.45);
  text-align: right;
  &.el-icon-time {
    &:before {
      color: #0f6dff;
      margin-right: 10px;
    }
  }
}
.notice-content_html {
  line-height: 22px;
  &.show-more {
    display: inline-block;
    width: 100%;
    text-overflow: ellipsis;
    -ms-text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    overflow: hidden;
    //display: -webkit-box;
    word-break: break-all;
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;

    line-clamp: 3;
    -webkit-line-clamp: 3;
    max-height: 66px;
    img {
      display: none;
    }
  }
}
</style>
