<template>
  <div class="left-nav">
    <div title="明源云天际服务云" class="logo-image" />
    <template v-if="hasNoLeftIcon">
      <el-link
        v-if="leftIcons.includes('Workbench')"
        :underline="false"
        :href="
          currentApp === 'web'
            ? null
            : `/v20/${
              BASE_URL.WEB
            }/account/user/back-to-workbench.svc?returnUrl=${getActiveUrl()}`
        "
        class="vice-text"
        :class="{ active: currentRoute === 'Workbench' }"
        @click="openWorkbench"
      >工作台</el-link>

      <div
        v-if="leftIcons.includes('Handle')"
        class="panel"
        @mouseenter="showMenu"
      >
        <el-link
          :underline="false"
          :href="current !== '全部产品' ? getCurrentUrl() : null"
          class="panel-content"
          :class="{ active: current !== '全部产品' }"
          @click.stop="showMenu"
        >
          {{ current }}
          <svg-icon
            :icon-class="
              current !== '全部产品' ? 'menu-list-active' : 'menu-list-dark'
            "
            class="menu-icon"
          />
        </el-link>
        <div
          v-show="menuShow"
          class="panel-menu"
          @click.stop="showMenu"
          @mouseleave="hideMenu"
        >
          <dl
            v-for="({ title, menus, multiColumn }, index) in menuList"
            :key="index"
            class="menu-list"
            :class="{ 'multi-column': multiColumn }"
          >
            <dt class="title">{{ title }}</dt>
            <dd class="menu-content">
              <el-popover
                v-for="{ label, alias, url, description } in menus"
                :key="label"
                placement="bottom"
                :append-to-body="false"
                popper-class="nav-popper"
                :disabled="!!url"
                width="200"
                trigger="hover"
                content="功能暂未开发完成，敬请期待"
                @mouseenter.stop
              >
                <template #reference>
                  <el-link
                    :underline="false"
                    class="menu"
                    :href="url ? `${url}?current=${alias}` : null"
                    :class="{ active: label === current, disabled: !url }"
                    @mouseenter.stop
                    @click.stop="openPage(url, alias)"
                  >
                    <div class="menu-name" :title="label">{{ label }}</div>
                    <div class="menu-description" :title="description">
                      {{ description || '--' }}
                    </div>
                  </el-link>
                </template>
              </el-popover>
            </dd>
          </dl>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import { getActiveHttp } from '@/components/HeaderNav/topNav/js/constantFun.js'
import { BASE_URL } from '@/utils/constants'

export default {
  name: 'Left',
  props: {
    leftIcons: {
      type: Array,
      default() {
        return ['Workbench', 'Handle']
      }
    },
    menuList: { type: Array, required: true },
    current: { type: String, required: true },
    currentApp: { type: String, default: '' },
    currentId: { type: String, default: '' }, // 当前工具alias
    hasNoLeftIcon: { type: Boolean, default: true } // 是否不需要图标
  },
  data() {
    return {
      BASE_URL,
      menuShow: false
    }
  },
  computed: {
    currentRoute() {
      return this.$route.name
    }
  },
  mounted() {
    window.addEventListener('click', this.hideMenu)
  },
  beforeDestroy() {
    window.removeEventListener('click', this.hideMenu)
  },
  methods: {
    getActiveUrl() {
      return getActiveHttp(
        'https://mysm.fdcyun.com',
        'https://mysm-test.fdcyun.com',
        `http://localhost:7777`,
        false,
        'https://auth-qa.fdcyun.com'
      )
    },
    getCurrentUrl() {
      const { menuList, current } = this
      let routes = []
      menuList.forEach((val) => {
        routes = [...routes, ...val.menus]
      })
      return routes.filter((routesItem) => routesItem.label === current)[0]
        ?.url
    },
    openWorkbench() {
      if (this.$route.name !== 'Workbench') {
        this.$router.push({ name: 'Workbench' })
      }
    },
    showMenu() {
      this.menuShow = true
    },
    hideMenu() {
      this.menuShow = false
    },
    openPage(url) {
      // if (!url) {
      //   this.$message.info(`工具建设中，敬请期待～`);
      // }
    }
  }
}
</script>
<style lang="scss">
.nav-popper {
  padding: 8px 12px !important;
  font-size: 12px !important;
}
</style>
<style lang="scss" scoped>
@import '../style/variables';
.left-nav {
  display: flex;
  position: relative;
  .logo-image {
    width: 146px;
    height: 30px;
    margin-top: 10px;
    background: url('../icon/logo_new.svg') no-repeat center center / 100% 100%;
  }
  .vice-text {
    // margin-top: 1px;
    margin-left: 12px;
    color: $titleFontColor;
    font-size: $titleFontSize;
    cursor: pointer;
    font-weight: normal;
    height: $navBarHeight;
    line-height: $navBarHeight;
    &::before {
      content: '';
      height: 30%;
      border-left: $border;
      margin-right: 12px;
    }
    &:hover,
    &.active {
      color: rgba($themeColor, 0.75);
    }
  }
  .panel {
    width: 120px;
    margin: 12px 0 0 12px;
    cursor: pointer;
    overflow: hidden;
    .menu-icon {
      float: right;
      width: 18px;
      height: 18px;
      margin-top: 5px;
    }
    .panel-content {
      height: 28px;
      line-height: 28px;
      padding: 0 8px;
      background-color: #f2f2f2;
      display: block;
      font-weight: normal;
      &.active {
        background-color: #e9ebee;
        color: #507afe;
        .menu-icon {
          width: 12px;
        }
      }
    }
  }
  .panel-menu {
    position: absolute;
    top: $navBarHeight + 2px;
    left: -$margin + 2px;
    width: 1122px;
    height: 482px;
    padding: 30px 32px 10px 32px;
    background-color: $backgroundColor;
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.1);
    z-index: 3004;
    .title {
      color: rgba(34, 34, 34, 1);
      font-size: 16px;
      margin-bottom: $margin;
      font-weight: 700;
      padding-left: 10px;
    }
    .menu-list {
      margin-bottom: $margin;
      &:first-of-type {
        .title {
          margin-top: 0px;
        }
      }
    }
    .menu {
      font-weight: normal;
      width: 20%;
      justify-content: left;
      margin-bottom: $padding - 8px;
      padding: 8px 12px;
      &:hover,
      &.active {
        border-radius: 3px;
        background: rgba(245, 245, 245, 1);
        .menu-name {
          color: rgba($themeColor, 0.7);
        }
        .menu-description {
          color: $minorFontColor;
        }
      }
      &.disabled {
        color: $disabledFontColor;
        cursor: not-allowed;
        .menu-name,
        .menu-description {
          color: inherit;
        }
      }
      ::v-deep .el-link--inner {
        display: grid;
      }
      .menu-name {
        width: 100%;
        font-size: 14px;
        color: $titleFontColor;
        margin-bottom: 12px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      .menu-description {
        font-size: 13px;
        color: $disabledFontColor;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }
}
</style>
